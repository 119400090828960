body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: url('background.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #333;
}

.home-container {
  text-align: center;
}

.navbar {
  background-color: rgba(32, 35, 42, 0.8);
  backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.navbar a {
  color: #ffffff;
  padding: 14px 20px;
  text-decoration: none;
  display: inline-block;
}

.navbar a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #61dafb;
}

.hero-section {
  background-image: url('background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 70px; /* Increase the font size for the main heading */
  font-weight: bold; /* Make the text bold */
  letter-spacing: 2px; /* Add some letter spacing for a modern look */
  margin-bottom: 20px;
  color: white;

  margin-top: -300px; /* Move the text higher */
}

.hero-content p {
  font-size: 30px; /* Increase the font size for the subheading */
  font-weight: 300; /* Lighter weight for a modern look */
  letter-spacing: 1px; /* Add some letter spacing for a modern look */
  margin-bottom: 40px;
}

.explore-button {
  background-color: #ffffff;
  color: #20232a;
  padding: 12px 24px;
  font-size: 20px; /* Slightly larger button text */
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.explore-button:hover {
  background-color: #21a1f1;
}

.mission-section {
  background-color: white;
  padding: 40px 20px;
  margin-top: -20px;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0 0;
}

.mission-section h2 {
  color: #20232a;
  font-size: 36px;
  margin-bottom: 20px;
}

.mission-section p {
  font-size: 18px;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.chart-section {
  padding: 20px;
  background-color: #20232a;
}

canvas {
  margin-top: 20px;
  max-width: 100%;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .navbar a {
    padding: 10px;
    font-size: 16px;
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }
}
