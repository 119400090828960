.high-rate {
  color: green;
  font-weight: bold;
}
div h2 {
  color: white !important;
}

.low-rate {
  color: red;
  font-weight: bold;
}

table {
  margin: 20px auto;
  border-collapse: collapse;
  width: 90%;
  max-width: 100%; /* Ensure the table doesn't exceed the container width */
  background-color: #fdfdfd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

th, td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 14px;
  font-size: 16px;
}

th {
  background-color: #3a3a3a;
  color: white;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  th, td {
      font-size: 14px;
      padding: 10px;
  }

  table {
      width: 100%;
      display: block;
      overflow-x: auto;
      white-space: nowrap;
  }

  /* This makes the table scrollable horizontally on small screens */
  table th, table td {
      white-space: nowrap;
  }
}
h2 {
  color: white;
}
