body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.App-header {
  /* background-color: #282c34; */
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  /* color: #61dafb; */
  color: white;
  text-decoration: none;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 70px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

nav ul li a:hover {
  background-color: #61dafb;
  color: #282c34;
  text-decoration: none;
}

h2 {
  text-align: center;
  margin-top: 40px;
  color: #20232a;
}

.high-rate {
  color: green;
  font-weight: bold;
}

.low-rate {
  color: red;
  font-weight: bold;
}

/* Add padding to the container holding the table */
.table-container {
  padding-top: 40px; /* Adjust the top padding to reduce the gap between the navbar and the table */
  padding-bottom: 40px; /* Add some padding below the table */
}

/* If you want to directly target the table */
table {
  margin-top: 40px; /* Add space above the table */
  margin-bottom: 40px; /* Add space below the table */
  border-collapse: separate;
  border-spacing: 0;
  width: 80%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

table th, table td {
  padding: 16px;
  text-align: left;
  font-size: 16px;
}

table th {
  background-color: #282c34;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
}

table tbody tr:hover {
  background-color: #f1f1f1;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Active link style */
nav ul li a.active {
  text-decoration: underline;
  color: white; /* Change color of the active link if you want */
}

