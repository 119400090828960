.about-us-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.about-us-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: white;
}

.about-us-container p {
    font-size: 24px;
    line-height: 1.6;
    color: white;
    margin-bottom: 10px; /* Reduce the space between paragraphs */
}

.qr-code {
    max-width: 150px;  /* Adjust the size as needed */
    margin: 0 auto; /* Center the image */
}

.contact-section img {
    margin-top: 0px;
    margin-bottom: 0px; /* Reduce the space above and below the image */
}

.contact-section p {
    margin-bottom: 5px; /* Reduce the space between text and QR code */
}
