.exchanges-container {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-buttons button {
  padding: 12px 25px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.toggle-buttons button.active {
  background-color: #282c34;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.toggle-buttons button:hover:not(.active) {
  background-color: #ddd;
}

.exchanges-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.exchanges-table th, .exchanges-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.exchanges-table th {
  background-color: #282c34;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.exchanges-table tr:hover {
  background-color: #f1f1f1;
}

