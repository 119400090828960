
.articles-content {
  width: 80%; /* Adjusted width to make the content narrower */
  max-width: 900px; /* Optional: Set a max-width */
  margin: 0 auto;
}

.article-preview {
  display: flex;
  margin-bottom: 20px;
  padding: 15px;
  background-color: white; 
  color: white; /* White text color for better contrast */
  border-radius: 10px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  max-width: 800px; /* Restrict each preview to a maximum width */
  margin: 10px auto; /* Center each article preview */
}

.article-preview:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow on hover */
  transform: translateY(-5px); /* Slightly lift the card on hover */
}

.article-details h3 {
  font-size: 24px;
  margin-bottom: 5px;
  color: black; /* Bright color for the title */
}

.article-details p {
  margin: 0;
  color: black; /* Lighter color for the description */
  text-decoration: none; /* Remove underline from the text */

}


.article-content-container {
  max-width: 800px; /* Width of the content container */
  margin: 20px auto; /* Center the container */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
