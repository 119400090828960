body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .privacy-container {
    max-width: 900px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  h1, h2 {
    color: #20232a;
    text-align: center;
  }
  
  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  ul {
    font-size: 18px;
    line-height: 1.6;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  